<template>
    <v-navigation-drawer
        :clipped="$vuetify.breakpoint.lgAndUp"
        v-model="drawer"
        dark
        color="black"
        app
    >
        <v-list dense>
            <template v-for="item in items">
                <v-list-item
                    :key="item.text"
                    @click="go(item.route)"
                    link
                >
                    <v-list-item-content>
                        <v-list-item-title class="neon-green text-uppercase text-left text-h6" :class="getClass(item.route)">
                            {{ $t(item.text) }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {text: 'plants', route: 'plants'},
                {text: 'chemistry', route: 'ingredients'},
                {text: 'resources', route: 'resources'},
            ],
        }
    },
    methods: {
        go(route) {
            if (route === 'logout') {
                this.$store.commit('logout');
            } else if (route) {
                this.$router.push({name: route});
            } else {
                return false;
            }
        },
        getClass(route) {
            return this.$route.path.match(route) ? 'neon-pink' : '';
        }
    },
    computed: {
        drawer: {
            get: function () {
                return this.$store.state.drawer;
            },
            set: function (drawerState) {
                this.$store.commit('setDrawer', drawerState);
            }
        }
    }
}
</script>

<style scoped>

</style>
