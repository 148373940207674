<template>
    <v-container fluid class="text-left">
        <h1 v-text="$t('chemistry')" class="neon-pink" v-if="!$store.state.drawer"></h1>
        <v-data-table
            dark
            dense
            :headers="headers"
            :items="filteredIngredients"
            :items-per-page="itemsPerPage"
            item-class="neon-green"
            sort-by="name"
            class="pointer"
            @click:row="onSelect"
        ></v-data-table>
    </v-container>
</template>

<script>
export default {
    name: "Ingredients",
    data() {
        return {
            ingredients: [] ,
            search: ''
        }
    },
    computed: {
        filteredIngredients() {
            return this.ingredients.filter(c => {
                return c.language === this.$store.state.currentLang && (
                    this.search === ''
                    || (c.name && c.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    || (c.description && c.description.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                )
            });
        },
        headers() {
            return [
                {text: this.$t('name'), value: 'name', class: 'neon-cyan'},
            ];
        },
        itemsPerPage() {
            return this.$vuetify.breakpoint.smAndUp ? -1 : 5;
        }
    },
    methods: {
        getIngredients() {
            this.axios.get('/v1/ingredients').then(response => {
                this.ingredients = response.data.data;
            });
        },
        onSelect(row) {
            this.$router.push({name: 'ingredient', params: {name: row.handle}})
        }
    },
    mounted() {
        document.title = 'Psychoaktiv.net - ' + this.$t('chemistry')
        this.getIngredients();
    }
}
</script>

<style scoped>

</style>