<template>
    <v-container fluid class="text-left">
        <h2 v-html="filteredIngredient.name" class="neon-pink"></h2>

        <v-row dense>
            <v-col cols="12" md="6">

                <v-row dense v-if="filteredIngredient.description">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('description')"></v-col>
                    <v-col class="neon-green" v-html="filteredIngredient.description"></v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
            </v-col>
        </v-row>
        <v-row dense v-if="filteredIngredient.images && filteredIngredient.images.length">
            <v-col v-for="img of filteredIngredient.images" :key="img.name" class="d-flex child-flex">
                <v-img  :src="img.src" :lazy-src="img.src" aspect-ratio="1" :max-height="img.height*2" :max-width="img.width*2"></v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Ingredient",
    data() {
        return {
            ingredient: [],
            search: ''
        }
    },
    computed: {
        filteredIngredient() {
            if (this.ingredient.length > 0) {
                let chem = this.ingredient.find(c => c.language === this.$store.state.currentLang);
                if (chem) {
                    return chem;
                }
                return this.ingredient[0];
            }
            return { name: '', description: ''}
        },
        headers() {
            return [
                {text: this.$t('name'), value: 'name', class: 'neon-cyan'},
            ];
        },
        itemsPerPage() {
            return this.$vuetify.breakpoint.smAndUp ? -1 : 5;
        }
    },
    methods: {
        getIngredient() {
            this.axios.get('/v1/ingredients/' + this.$route.params.name).then(response => {
                this.ingredient = response.data.data;
            })
        },
        onSelect(row) {
            this.$router.push({name: 'ingredient', params: {name: row.handle}})
        }
    },
    mounted() {
        document.title = 'Psychoaktiv.net - ' + this.$t('chemistry') + ' - ' + this.$route.params.name;
        this.getIngredient();
    }
}
</script>

<style scoped>

</style>