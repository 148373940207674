import Vue from 'vue'
import Vuex from 'vuex'
import i18n from "@/i18n";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      authenticated: false,
      currentLang: 'de',
      drawer: false,
      initialized: false,
      languages: [
          {cc: 'de'},
          {cc: 'en'}
      ],
      user: {
          id: '',
          username: '',
          email: null,
          gruppe: ''
      },
      token: null,
      showLogin: false,
  },
  mutations: {
      initialiseUser(state) {
          let storedUser = localStorage.getItem('user');
          if (storedUser) {
              state.user = JSON.parse(storedUser);
              state.token = localStorage.getItem('token');
              state.authenticated = true;
          }
          let lang = localStorage.getItem('lang');
          if (lang) {
              state.currentLang = lang;
              this.commit('setLang', lang);
          }
          state.initialized = true;
      },
      setDrawer(state, drawerState) {
          state.drawer = drawerState;
      },
      login(state, data) {
          state.user = data.user;
          state.token = data.token;
          state.authenticated = true;
          localStorage.setItem('user', JSON.stringify(data.user));
          localStorage.setItem('token', data.token);
      },
      logout(state) {
          state.user = null;
          state.token = null;
          state.authenticated = false;
          localStorage.removeItem('token');
      },
      showLogin(state, data) {
          state.showLogin = data;
      },
      setLang(state, payload) {
          i18n.locale = payload;
          state.currentLang = payload;
          localStorage.setItem('lang', payload);
      },
  },
  actions: {
      initialize({commit}) {
          commit('initialiseUser')
      },
      logout({commit}) {
          commit('setUser', null)
      },
      setLang({commit}, lang) {
          commit('setLang', lang)
      }
  },
  modules: {
  }
})
