export default {
    install(Vue) {
        let tools = {
            debounce(func, wait) {
                var self = this, args = arguments;
                var later = function () {
                    this.timeout = null;
                    func.apply(self, args);
                };
                clearTimeout(this.timeout);
                this.timeout = setTimeout(later, wait);
            },
            flag(language) {
                if (language === 'en') {
                    return 'gb';
                }
                return language;
            },
            openResourceLink(res) {
                let link = false;
                if (res.asin) {
                    link = 'https://www.amazon.de/dp/'+res.asin+'?tag=dreampage-21&linkCode=osi&th=1&psc=1';
                } else if (res.url) {
                    link = res.url;
                } else {
                    return false;
                }
                Object.assign(document.createElement('a'), {
                    target: '_blank',
                    href: link,
                }).click();
            }
        };
        Vue.prototype.$tools = tools;
        this.__proto__ = tools;
    }
}
