<template>
    <v-app>
        <my-navigation></my-navigation>
        <my-toolbar></my-toolbar>
        <v-main style="background-color: black">
            <v-container fluid>
                <v-row align-content="start"
                       dense
                       no-gutters>
                    <v-col cols="12">
                        <router-view></router-view>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import myNavigation from './components/navigation'
import myToolbar from './components/toolbar'

export default {
    name: 'App',
    components: {
        myNavigation, myToolbar
    },
    mounted() {
        this.$store.commit('setDrawer', this.$vuetify.breakpoint.smAndUp);
    }
}
</script>

<style lang="scss">
@import './assets/css/index.scss';
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
</style>
