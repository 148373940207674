<template>
    <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        color="black"
        dark
        app
        fixed
    >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"
                            class="neon--text"/>
        <v-toolbar-title style="padding-left: 0">
            <a href="/" class="font-weight-bold neon-green text-h4 text-decoration-none">Psychoaktiv.net</a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="mr-3">

            <v-menu offset-y
                    class="mr-2">
                <template v-slot:activator="{ on }">
                    <v-btn small
                           depressed
                           color="transparent"
                           v-on="on">
                        <country-flag :country="$tools.flag($store.state.currentLang)"></country-flag>
                    </v-btn>
                </template>
                <v-list dark>
                    <v-list-item
                        v-for="lang of $store.state.languages"
                        :key="lang.cc"
                        @click="setLang(lang.cc)"
                    >
                        <v-list-item-title>
                            <country-flag :country="$tools.flag(lang.cc)"></country-flag>
                        </v-list-item-title>
                    </v-list-item>
                </v-list>

            </v-menu>
        </div>
    </v-app-bar>
</template>

<script>


export default {
    data() {
        return {
            flags: {}
        }
    },
    computed: {
        drawer: {
            get: function () {
                return this.$store.state.drawer;
            },
            set: function (drawerState) {
                this.$store.commit('setDrawer', drawerState);
            }
        }
    },
    methods: {
        setLang: function (lang) {
            this.$store.commit('setLang', lang);
            this.$vuetify.lang.current = lang;
        },
        flag(language) {
            if (language === 'en') {
                return 'gb';
            }
            return language;
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
.small-flag {
    transform: scale(0.3) !important;
    -ms-transform: scale(0.3) !important;
    -webkit-transform: scale(0.3) !important;
    -moz-transform: scale(0.3) !important;
}

.normal-flag {
    margin: 0em -0.9em 0em -0.7em !important;
    transform: scale(0.5);
    -ms-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
}
</style>
