<template>
    <v-card>
        <v-card-title>
            <span class="headline"><slot name="header">{{$t('login').toUpperCase()}}</slot></span>
        </v-card-title>
        <v-card-text>
            <v-form>
                <v-text-field
                    v-model="login.username"
                    label="Username/Email"
                    @keyup.enter="$refs.password.focus"
                    prepend-icon="mdi-account"
                ></v-text-field>
                <v-text-field
                    v-model="login.password"
                    prepend-icon="mdi-lock-question"
                    :append-icon="showPass ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPass ? 'text' : 'password'"
                    :label="$t('password')"
                    class="input-group--focused"
                    ref="password"
                    @keyup.enter="doLogin()"
                    @click:append="showPass = !showPass"
                ></v-text-field>
            </v-form>
            <v-row>
                <v-col cols="12">
                    <v-alert color="error" icon="mdi-alert-outline" :value="login.error">{{$t('login failed')}}: {{login.errorMsg}}</v-alert>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" flat @click="onCancel()">{{$t('cancel')}}</v-btn>
            <v-btn color="green" flat @click="doLogin()" :disabled="login.username==='' || login.password===''">{{$t('login')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "loginDialog",
        data() {
            return {
                login: {
                    username: '',
                    password: '',
                    error: false,
                    errorMsg: ''
                },
                showPass: false,
                rules: {
                    required: value => !!value || 'Required.'
                }
            }
        },
        methods: {
            doLogin() {
                this.login.error = false;
                axios.post('v1/users/login',this.login).then(response => {
                    if (!response.error) {
                        //this.$store.commit('setUser', response.data);
                        this.$store.commit('showLogin', false);
                        this.$store.commit('login', response.data);
                    } else {
                        this.login.error = true;
                        this.login.errorMsg = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                    this.login.error = true;
                    this.login.errorMsg = error.data;
                });
            },
            onCancel() {
                this.login.username = '';
                this.login.password = '';
                this.login.error = false;
                this.$store.commit('showLogin',false);
            }
        }
    }
</script>

<style scoped>

</style>
