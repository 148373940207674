<template>
    <v-container fluid class="text-left">
        <v-list dense dark color="black" v-if="resources">
            <h1 v-text="$t('resources')" class="neon-pink" v-if="!$store.state.drawer"></h1>
            <v-list-item
                v-for="(item, i) in resources"
                :key="i"
                class="pointer neon-green"
                @click="$tools.openResourceLink(item)"
            >
                <v-icon v-text="'mdi-'+item.type" class="mr-2" color="#48ff00"></v-icon>
                <v-list-item-content>
                    <v-list-item-title class="text-wrap">
                        <span class="orange--text" v-text="item.name"></span>
                        <span v-if="item.author && notMobile" class="green--text ml-2">{{item.author}}</span>
                        <span v-if="item.year && notMobile" class="green--text"> ({{item.year}})</span>
                        <country-flag :country="$tools.flag(item.lang)" size="small" v-if="notMobile && item.lang !== $store.state.currentLang"></country-flag>
                    </v-list-item-title>
                    <v-list-item-subtitle class="green--text text-wrap" v-if="!notMobile">
                        <span v-if="item.author">{{item.author}}</span>
                        <span v-if="item.year"> ({{item.year}})</span>
                        <country-flag :country="$tools.flag(item.lang)" size="small" v-if="!notMobile && item.lang !== $store.state.currentLang"></country-flag>
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-container>
</template>

<script>
export default {
    name: "Resources",
    data() {
        return {
            resources: []
        }
    },
    computed: {
        notMobile() {
            return this.$vuetify.breakpoint.smAndUp;
        }
    },
    methods: {
        getResources() {
            this.axios.get('/v1/resources').then(response => {
                this.resources = response.data.data;
            })
        }
    },
    mounted() {
        document.title = 'Psychoaktiv.net - ' + this.$t('resources')
        this.getResources()
    }
}
</script>

<style scoped>

</style>