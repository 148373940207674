<template>
    <v-container fluid class="text-left">
        <h1 v-text="$t('plants')" class="neon-pink" v-if="!$store.state.drawer"></h1>
        <v-text-field v-model="search" :label="$t('search')" class="neon-cyan"  clearable dense dark></v-text-field>
        <v-data-table
            dark
            dense
            :headers="headers"
            :items="filteredPlants"
            :items-per-page="itemsPerPage"
            item-class="neon-green"
            sort-by="name"
            class="pointer"
            @click:row="onSelect"
        ></v-data-table>
    </v-container>
</template>

<script>
export default {
    name: "Plants",
    data() {
        return {
            search: '',
            plants: []
        }
    },
    computed: {
        filteredPlants() {
            return this.plants.filter(p => {
                return p.language === this.$store.state.currentLang && (
                    this.search === ''
                    || (p.name && p.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    || (p.name_lang && p.name_lang.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    || (p.name_other && p.name_other.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    || (p.familie && p.familie.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                    || (p.chemie && p.chemie.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
                )
            });
        },
        headers() {
            if (this.$vuetify.breakpoint.smAndUp) {
                return [
                    {text: this.$t('botanical_name'), value: 'name', class: 'neon-cyan'},
                    {text: this.$t('name'), value: 'name_lang', class: 'neon-cyan'},
                    {text: this.$t('family'), value: 'familie', class: 'neon-cyan'},
                ]
            } else {
                return [
                    {text: this.$t('botanical_name_short'), value: 'name', class: 'neon-cyan'},
                    {text: this.$t('name'), value: 'name_lang', class: 'neon-cyan'},
                ]
            }
        },
        itemsPerPage() {
            return this.$vuetify.breakpoint.smAndUp ? -1 : 5;
        }
    },
    methods: {
        getPlants() {
            this.axios.get('/v1/plants').then(response => {
                this.plants = response.data.data;
            })
        },
        onSelect(row) {
            this.$router.push({name: 'plant', params: {name: row.handle}})
        }
    },
    mounted() {
        document.title = 'Psychoaktiv.net - ' + this.$t('plants');
        this.getPlants();
    },
    watch: {

    }
}
</script>

<style scoped>

</style>