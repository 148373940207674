import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import i18n from "@/i18n";

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#48ff00',
                secondary: '#ff00dd',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',
            },
        },
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
});
