<template>
    <v-container fluid class="text-left">
        <h2 v-html="filteredPlant.name" class="neon-pink"></h2>
        <v-row dense>
            <v-col cols="12" md="6">
                <v-row dense v-if="filteredPlant.name_lang">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('name')"></v-col>
                    <v-col class="neon-green" v-html="filteredPlant.name_lang"></v-col>
                </v-row>
                <v-row dense v-if="filteredPlant.name_other">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('name_other')"></v-col>
                    <v-col class="neon-green" v-html="filteredPlant.name_other"></v-col>
                </v-row>
                <v-row dense v-if="filteredPlant.familie">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('family')"></v-col>
                    <v-col class="neon-green" v-html="filteredPlant.familie"></v-col>
                </v-row>
                <v-row dense v-if="filteredPlant.beschreibung">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('description')"></v-col>
                    <v-col class="neon-green" v-html="filteredPlant.beschreibung"></v-col>
                </v-row>
                <v-row dense v-if="filteredPlant.vorkommen">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('occurrence')"></v-col>
                    <v-col class="neon-green" v-html="filteredPlant.vorkommen"></v-col>
                </v-row>
                <v-row dense v-if="filteredPlant.jahreszeit">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('season')"></v-col>
                    <v-col class="neon-green" v-html="filteredPlant.jahreszeit"></v-col>
                </v-row>
                <v-row dense v-if="filteredPlant.chemie">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('chemistry')"></v-col>
                    <v-col class="neon-green" v-html="filteredPlant.chemie"></v-col>
                </v-row>
                <v-row dense v-if="filteredPlant.zubereitung">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('preparation')"></v-col>
                    <v-col class="neon-green" v-html="filteredPlant.zubereitung"></v-col>
                </v-row>
                <v-row dense v-if="filteredPlant.geschichte">
                    <v-col class="col-3 col-xs6 neon-cyan" v-text="$t('history')"></v-col>
                    <v-col class="neon-green" v-html="filteredPlant.geschichte"></v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <v-list dense dark color="black" v-if="filteredPlant.resources">
                    <span class="neon-cyan" v-text="$t('resources')"></span>
                    <v-list-item
                        v-for="(item, i) in filteredPlant.resources"
                        :key="i"
                        class="pointer neon-green"
                        @click="$tools.openResourceLink(item)"
                    >
                        <v-icon v-text="'mdi-'+item.type" class="mr-2" color="#48ff00"></v-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.name" class="orange--text"></v-list-item-title>
                            <v-list-item-subtitle class="green--text">
                                <span v-if="item.author">{{item.author}}</span>
                                <span v-if="item.year"> ({{item.year}})</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-row>
        <v-row dense v-if="filteredPlant.images && filteredPlant.images.length">
            <v-col v-for="img of filteredPlant.images" :key="img.name" class="d-flex child-flex">
                <v-img  :src="img.src" :lazy-src="img.src" aspect-ratio="1" :max-height="img.height*2" :max-width="img.width*2"></v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Plant",
    data() {
        return {
            plants: [],
            empty: {
                handle: '',
                name: '',
                name_lang: '',
                name_other: '',
                familie: '',
                beschreibung: '',
                vorkommen: '',
                jahreszeit: '',
                chemie: '',
                zubereitung: '',
                geschichte: '',
                resources: ''
            }
        }
    },
    computed: {
        filteredPlant() {
            if (this.plants.length) {
                let plant = this.plants.find(p => p.language === this.$store.state.currentLang);
                if (plant) {
                    return plant;
                }
                return this.plants[0];
            }
            return this.empty;
        }
    },
    methods: {
        getPlant() {
            this.axios.get('/v1/plants/' + this.$route.params.name).then(response => {
                this.plants = response.data.data;
            })
        }
    },
    mounted() {
        document.title = 'Psychoaktiv.net - ' + this.$t('plants') + ' - ' + this.$route.params.name.replace('.',' ');
        this.getPlant();
    }
}
</script>

<style scoped>

</style>