import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import axios from 'axios'
import CountryFlag from "vue-country-flag";
import tools from '@/plugins/tools';

Vue.use(tools);

Vue.component('country-flag', CountryFlag);

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL
Vue.prototype.axios = axios;
window.__proto__.axios = axios;

new Vue({
    router,
    store,
    tools,
    vuetify,
    i18n,
    render: h => h(App),
    beforeCreate() {
        this.$store.commit('initialiseUser');
    }
}).$mount('#app')
