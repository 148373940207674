import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
import Home from './views/Home.vue'
import Plant from "@/views/Plant";
import Plants from "@/views/Plants";
import Resources from "@/views/Resources";
import Login from "@/views/Login";
import Ingredients from "@/views/Ingredients";
import Ingredient from "@/views/Ingredient";
import i18n from './i18n'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/plants/:name',
        name: 'plant',
        component: Plant
    },
    {
        path: '/plants',
        name: 'plants',
        component: Plants
    },
    {
        path: '/chemistry/:name',
        name: 'ingredient',
        component: Ingredient
    },
    {
        path: '/chemistry',
        name: 'ingredients',
        component: Ingredients
    },
    {
        path: '/resources',
        name: 'resources',
        component: Resources
    },
    {
        path: '/login',
        name: 'login',
        component: Login
    },
]

let router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const originalPush = router.push;
const originalReplace = router.replace;
router.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => {
        // console.log(err, location);
    })
};
router.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => {
        // console.log(err, location);
    })
};

router.beforeEach((to, from, next) => {
    const protectedPages = [];
    const authRequired = protectedPages.includes(to.name);

    function proceed() {
        if (authRequired && !store.state.authenticated) {
            console.log('not authenticated, redirect to login')
            next('/login');
        }
        next();
    }

    let pathArray = to.path.split('/');
    if (pathArray.length > 1) {
        let lang = pathArray[1];
        if (lang === 'de' || lang === 'en') {
            pathArray.splice(1,1);
            store.commit('setLang', lang);
            next({path: pathArray.join('/'), replace: true});
        }
    }



    window.gtag('set', 'page_path', to.path);
    window.gtag('event', 'page_view');
    if (!store.state.initialized) {
        console.log('initializing...')
        store.dispatch('initialize').then(() => {
            console.log('done, proceed')
            proceed();
        }).catch(() => {
            store.commit('error', {text: i18n.t('error_initialization')})
        });
    } else {
        proceed();
    }
});


export default router
