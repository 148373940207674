<template>
    <div class="home"
         style="heigth: 100vh">
        <v-container fluid>
            <div v-if="$store.state.currentLang==='de'"
                 class="neon-pink text-h6">
                Diese Webseite bietet eine Plattform zur Information über psychoaktive Pflanzen.<br>
                Die Seite dient nicht als Anleitung und Aufforderung zur Einnahme von Drogen sondern dient
                ausschließlich der Information. <br>
                Für alle hier gemachten Angaben übernimmt der Betreiber der Webseite keine Haftung. <br>
                Vor Selbstversuchen sei gewarnt, jeder handelt eigenverantwortlich.<br>
            </div>
            <div v-else
                 class="neon-pink text-h6">
                This website provides a platform for information about psychoactive plants.<br>
                The site is for informational purposes only and not intended as a guide or solicitation to take drugs.
                <br>
                The operator of the website takes no liability for any information provided here. <br>
                Be warned against self-experiments, everyone acts on their own responsibility.<br>
            </div>
            <div class="mt-5">
                <v-row>
                    <v-col>
                        <router-link :to="{name: 'plants'}"
                                     v-text="$t('plants')"
                                     class="text-h6"></router-link>
                    </v-col>
                    <v-col>
                        <router-link :to="{name: 'ingredients'}"
                                     v-text="$t('chemistry')"
                                     class="text-h6"></router-link>
                    </v-col>
                    <v-col>
                        <router-link :to="{name: 'resources'}"
                                     v-text="$t('resources')"
                                     class="text-h6"></router-link>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'Home',
    mounted() {
        document.title = 'Psychoaktiv.net'
    }
}
</script>
